<template>
  <wysiwyg v-model="content" />
</template>

<script>

import Wysiwyg from '@/components/inputs/wysiwyg';

export default {
  name: 'NoteEditor',

  props: {
    field: String,
    note: String,
  },

  components: { Wysiwyg },

  data() {
    return {
      content: this.note,
    };
  },

  computed: {
    saveBtnEnabled() {
      return this.note !== this.content;
    },
  },

  methods: {
    save(close) {
      this.$store.commit('updateField', {
        field: this.field,
        value: this.content,
      });
      this.$store.commit('setCurrentChanged', true);

      close();
    },
  },
};
</script>
